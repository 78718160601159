import React from "react";
import ProudSection from "@common/CommonSections/ProudSection/ProudSection";
import { ProudSectionContainer } from "./ProudSectionWrapper.style";

const ProudSectionWrapper = ({ ...rest }) => (
    <ProudSectionContainer>
        <ProudSection {...rest} />
    </ProudSectionContainer>
);

export default ProudSectionWrapper;
